import React from 'react';

const Airtable = ({ name, iframeSrc }) => {
  return (
    <section className="extras">
      <h2
        className="extras-heading"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <span style={{ textAlign: 'left' }}>{name}</span>
      </h2>
      <iframe
        className="airtable-embed"
        src={iframeSrc}
        frameBorder="0"
        onMouseWheel=""
        width="100%"
        height="533"
        style={{ background: 'transparent', border: '1px solid #ccc' }}
      ></iframe>
    </section>
  );
};

export default Airtable;
