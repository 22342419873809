import React from "react";
import mainLogo from'/Users/bryantung/h/source/DonutToGo/src/logo.png';

export default function Logo() {
  return (
    <div className="logo">
          <img  src={mainLogo} className="logo" alt="logo"/>
    </div>
  );
}
 