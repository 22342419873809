import Logo from './components/Logo';
import Donuts from './components/Donuts';
import Extras from './components/Extras';
import { Provider } from './Context';
import React, { useState } from 'react';
import {
  cakeDonuts,
  raisedDonuts,
  oldFashioned,
  hotDrinks,
  cappuccino,
  icedDrink,
  frap,
  smoothies,
} from './data';
import './styles.css';
import Drinks from './components/Drinks';
// import logo from './logo.png';
// import ImageModal from './components/ImageModal';
import Airtable from './components/airtable';
export default function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleLinkClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Provider>
      {/* <a href="#" onClick={handleLinkClick}>
        Open Image Modal
      </a> */}
      {/* {isModalOpen && <ImageModal imageUrl={logo} closeModal={closeModal} />} */}
      <div className="menu">
        <Logo />
        <Airtable
          name="Doughnuts"
          iframeSrc="https://airtable.com/embed/shrMWncWYCESAQGY3?backgroundColor=yellowLight"
        />
        <Airtable
          name="Hot Drinks"
          iframeSrc="https://airtable.com/embed/shrTcwyE01YuDgeSE?backgroundColor=yellowLight"
        />
        <Airtable
          name="Iced Drinks"
          iframeSrc="https://airtable.com/embed/shrR2V8sYBibeZWyn?backgroundColor=yellowLight"
        />
        <Airtable
          name="Lemonade"
          iframeSrc="https://airtable.com/embed/shrn9dLfPjwU7pZNn?backgroundColor=yellowLight"
        />
        <Airtable
          name="Smoothies"
          iframeSrc="https://airtable.com/embed/shre0z4USjZv3m2mz?backgroundColor=yellowLight"
        />

        {/* <Mains meals={mains} /> */}
        {/* <Donuts donut={cakeDonuts} />
        <Donuts donut={raisedDonuts} />

        <Donuts donut={oldFashioned} />
        <Drinks drink={hotDrinks} />
        <Drinks drink={cappuccino} />
        <Drinks drink={icedDrink} />
        <Drinks drink={frap} />
        <Drinks drink={smoothies} /> */}
      </div>
    </Provider>
  );
}
